const routes = require('../../../public/js/fos_js_routes.json');
import Routing from '../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';

import Notification from './Notification.js';
import Loader from "./Loader";

Routing.setRoutingData(routes);

export default class Refurbish {

    /**
     * @returns {*}
     * @param form
     */
    getProductBySerialNumberAjax(form) {
        let LoaderObject = new Loader();

        let loaderSpinner = LoaderObject.createLoader($('.refurbish'));

        return $.when($.ajax({
            type: 'POST',
            url: Routing.generate('refurbish_main'),
            contentType: false,
            processData: false,
            data: new FormData(form),
            success: function (response) {
                LoaderObject.removeLoader(loaderSpinner);

                if (typeof response.serialNumber !== 'undefined') {
                    window.location.href = Routing.generate('refurbish_process', {
                        'serialNumber': response.serialNumber
                    });
                } else {
                    const NotificationObject = new Notification();
                    NotificationObject.setErrorAlert(xhr.responseJSON.error);
                }
            },
            error: function (xhr, status, error) {
                LoaderObject.removeLoader(loaderSpinner);

                const NotificationObject = new Notification();
                NotificationObject.setErrorAlert(xhr.responseJSON.error);
            }
        }))
    }

    reloadRefurbishProcess(data) {
        let LoaderObject = new Loader();
        LoaderObject.createLoader($('.refurbish'));
        let productSerialNumber = $('.refurbish').data('serial-number');

        let reloadedSelects = {
                    'serialNumber': productSerialNumber
        };

        reloadedSelects = {...reloadedSelects, ...data};
        window.location.href = Routing.generate('refurbish_process', reloadedSelects)
    }

    setBubble(range, bubble) {
        const val = range.value;
        const min = range.min ? range.min : 0;
        const max = range.max ? range.max : 100;
        const newVal = Number(((val - min) * 100) / (max - min));
        $(bubble).html(val);
      
        // Sorta magic numbers based on size of the native UI thumb
        // bubble.css("left", `calc(${newVal}% + (${8 - newVal * 0.15}px))`);
        $(bubble).css("left", `${newVal}%`);


      }

      
    validateSku(name, sku, mode, input, productId, requestedQuantity) {
        let LoaderObject = new Loader();

        let loaderSpinner = null;
        var data = {
            'name': name,
            'sku': sku,
            'requestedQuantity': requestedQuantity
        };

        if (typeof productId !== 'undefined' && productId) {
            data.productId = productId;
        }

        var accessoriesArea = null;
        var accessoriesInput = null;
        var badgeClass = null;

        if (mode === 'in') {
            loaderSpinner = LoaderObject.createLoader($('.accessories-in'));
            data.mode = 'in';
            accessoriesArea = $('.accessories-in-content');
            accessoriesInput = $('.in');
            badgeClass = 'accessories-in-badge';
        }

        if (mode === 'out') {
            loaderSpinner = LoaderObject.createLoader($('.accessories-out'));
            data.mode = 'out';
            accessoriesArea = $('.accessories-out-content');
            accessoriesInput = $('.out');
            badgeClass = 'accessories-out-badge';
        }

        return $.when($.ajax({
            type: 'POST',
            url: Routing.generate('refurbish_accessory_sku_validate'),
            data: data,
            success: (response) => {
                LoaderObject.removeLoader(loaderSpinner);

                let values = accessoriesInput.val().length > 1 ? accessoriesInput.val().split(',') : [];

                values.push(response.data);

                let badge = '<div class="accesory-badge"><p data-accessory-id="' + response.accId + '" class="badge ' + badgeClass + ' badge-info">' + response.data + '</p>' + response.name.replace(/\+/g, ' ') + '</div>';
                accessoriesArea.append(badge);
                accessoriesInput.val(values.join(','));
            },
            error: (xhr, status, error) => {
                LoaderObject.removeLoader(loaderSpinner);

                if (xhr.responseJSON.code === 0) {
                    const NotificationObject = new Notification();
                    NotificationObject.setErrorAlert(xhr.responseJSON.error);
                } else {
                    if (mode === 'in') {
                        input.closest('.accessories-in').find('.in-sku').focus();
                    }

                    if (mode === 'out') {
                        input.closest('.accessories-out').find('.out-sku').focus();
                    }
                }
            }
        }))
    }

    /**
     *
     */
    handleEvent() {
        let $refurbish = $('.refurbish');
        let $refurbishSearchForm = $refurbish.find('.search-form');

        let licenseSerialNumber = $('.license-serial-number');
        licenseSerialNumber.focus();


        let $refurbishUpdateForm = $('.form.refurbish.update-form');
        let productId = parseInt($refurbishUpdateForm.data('product-id'));

        // let $refurbishUpdateForm = $('form[name ="process_form"]');

        if ($refurbishSearchForm.length > 0) {
            let $searchInput = $refurbishSearchForm.find('[data-search-serial-number]');

            $searchInput.focus();

            $searchInput.on('keyup', (event) => {
                event.preventDefault();

                let keyCode = event.keyCode || event.which;

                if (keyCode === 13) {
                    $refurbishSearchForm.trigger('submit');
                }
            });

            $refurbishSearchForm.on('submit', (event) => {
                event.preventDefault();

                this.getProductBySerialNumberAjax(event.target);
            });
        }

        $refurbish.on('keydown keypress', (event) => {
            let keyCode = event.keyCode || event.which;

            if (keyCode === 13) {
                event.preventDefault();

                return false;
            }
        });

        let $accessoriesIn = $refurbish.find('.accessories-in');
        let $accessoriesInInput = $accessoriesIn.find('.in-sku');
        let $accessoriesInName = $accessoriesIn.find('.in-name');
        let accessoriesInHiddenInput = $accessoriesIn.find('.in');

        if ($accessoriesInInput.length > 0) {
            if (accessoriesInHiddenInput.val() !== '') {
                alert('W produkcie znajdują się akcesoria włożone!');
            }
            $accessoriesInInput.on('keyup', (event) => {
                event.preventDefault();

                let keyCode = event.keyCode || event.which;

                if (keyCode === 13) {
                    let sku = $(event.currentTarget).val();
                    let accessoriesInHiddenInputValues = accessoriesInHiddenInput.val();

                    if (accessoriesInHiddenInputValues.length === 0) {
                        accessoriesInHiddenInputValues = [];
                    } else {
                        accessoriesInHiddenInputValues = accessoriesInHiddenInputValues.split(',');
                    }

                    let requestedQuantity = accessoriesInHiddenInputValues.filter(x => x === sku).length + 1;
                    this.validateSku($accessoriesInName.val(), sku, 'in', $(event.currentTarget), productId, requestedQuantity);
                    $(event.currentTarget).val('');
                    $accessoriesInName.val('');
                }
            });

            $accessoriesInName.on('keyup', (event) => {
                event.preventDefault();

                let keyCode = event.keyCode || event.which;

                if (keyCode === 13) {
                    let sku = null;
                    let name = $accessoriesInName.val();
                    this.validateSku(name, sku, 'in', $(event.currentTarget), productId);
                    $(event.currentTarget).val('');
                    $accessoriesInName.val('');
                }
            });
        }

        let $accessoriesOut = $refurbish.find('.accessories-out');
        let $accessoriesOutInput = $accessoriesOut.find('.out-sku');
        let $accessoriesOutName = $accessoriesOut.find('.out-name');
        let accessoriesOutHiddenInput = $accessoriesOut.find('.out');
        
        if ($accessoriesOutInput.length > 0) {
            if (accessoriesOutHiddenInput.val() !== '') {
                alert('W produkcie znajdują się akcesoria wyciągnięte!');
            }
            $accessoriesOutInput.on('keyup', (event) => {
                event.preventDefault();

                let keyCode = event.keyCode || event.which;

                if (keyCode === 13) {
                    let sku = $(event.currentTarget).val();
                    this.validateSku($accessoriesOutName.val(), sku, 'out', $(event.currentTarget), productId);
                    $(event.currentTarget).val('');
                    $accessoriesOutName.val('');
                }
            });

            $accessoriesOutName.on('keyup', (event) => {
                event.preventDefault();

                let keyCode = event.keyCode || event.which;

                if (keyCode === 13) {
                    let sku = null;
                    this.validateSku($accessoriesOutName.val(), sku, 'out', $(event.currentTarget), productId);
                    $(event.currentTarget).val('');
                    $accessoriesOutName.val('');
                }
            });

            let $accessoriesInArea = $('.accessories-in-area');
            let $accessoriesOutArea = $('.accessories-out-area');

            $accessoriesInArea.on('click', (event) => {
                $(event.currentTarget).closest('.product-accessories').find('.in-sku').focus();
            });

            $accessoriesOutArea.on('click', (event) => {
                $(event.currentTarget).closest('.product-accessories').find('.out-sku').focus();
            });

            $(document).on('click', '.accessories-in-badge', (event) => {
                let LoaderObject = new Loader();
                let accessoryId = $(event.currentTarget).data('accessoryId');
                let loaderSpinner = LoaderObject.createLoader($(".accessories-in-area"));
                return $.when($.ajax({
                    type: 'POST',
                    url: Routing.generate('refurbish_accessory_product_remove', {
                        accessoryProduct: accessoryId
                    }),
                    success: (response) => {
                        LoaderObject.removeLoader(loaderSpinner);

                        const NotificationObject = new Notification();
                        NotificationObject.setSuccessAlert(response.success);

                        let badgeValue = $(event.target).text();
                        let accessoriesInput = $('.in');
        
                        $(event.target).parent('.accesory-badge').remove();
        
                        let values = accessoriesInput.val().length > 1 ? accessoriesInput.val().split(',') : [];
        
                        let index = values.indexOf(badgeValue);
                        if (index > -1) {
                            values.splice(index, 1);
                        }
        
                        accessoriesInput.val(values.join(','))
                    },
                    error: (xhr, status, error) => {
                        LoaderObject.removeLoader(loaderSpinner);
                            const NotificationObject = new Notification();
                            NotificationObject.setErrorAlert(xhr.responseJSON.error);
                    }
                }));
            });

            $(document).on('click', '.accessories-out-badge', (event) => {
                let LoaderObject = new Loader();
                let accessoryId = $(event.currentTarget).data('accessoryId');
                let loaderSpinner = LoaderObject.createLoader($(".accessories-out-area"));
                return $.when($.ajax({
                    type: 'POST',
                    url: Routing.generate('refurbish_accessory_product_remove', {
                        accessoryProduct: accessoryId
                    }),
                    success: (response) => {
                        LoaderObject.removeLoader(loaderSpinner);

                        const NotificationObject = new Notification();
                        NotificationObject.setSuccessAlert(response.success);

                        let badgeValue = $(event.target).text();
                        let accessoriesInput = $('.out');
        
                        $(event.target).parent('.accesory-badge').remove();
        
                        let values = accessoriesInput.val().length > 1 ? accessoriesInput.val().split(',') : [];
        
                        let index = values.indexOf(badgeValue);
                        if (index > -1) {
                            values.splice(index, 1);
                        }
        
                        accessoriesInput.val(values.join(','))
                    },
                    error: (xhr, status, error) => {
                        LoaderObject.removeLoader(loaderSpinner);
                            const NotificationObject = new Notification();
                            NotificationObject.setErrorAlert(xhr.responseJSON.error);
                    }
                }));
            });
        }

        let queryString = window.location.search;
        let reloadParams = new URLSearchParams(queryString);
        let reloadData = {};
        if (reloadParams.has('model')) {
            reloadData.model = reloadParams.get('model')
        }
        if (reloadParams.has('cpu')) {
            reloadData.cpu = reloadParams.get('cpu')
        }
        if (reloadParams.has('gpu')) {
            reloadData.gpu = reloadParams.get('gpu')
        }
        let $modelSelect = $refurbishUpdateForm.find('#process_form_model');

        if ($modelSelect.length > 0) {
            let option = $modelSelect.find("option:selected");
            if ($(option).hasClass('not-completed')) {
                $('.badge-not-completed').show();
                $refurbishUpdateForm.find('#process_form_submit').hide();
            } else {
                $('.badge-not-completed').hide();
                $refurbishUpdateForm.find('#process_form_submit').show();    
            }
        }
        let $cpuSelect = $refurbishUpdateForm.find('#process_form_cpu');
        let $gpuSelect = $refurbishUpdateForm.find('#process_form_gpu');
        let $caseTypeSelect = $refurbishUpdateForm.find('#process_form_caseType');

        $modelSelect.on('change', (event) => {
            event.preventDefault();

            let value = $(event.currentTarget).val();

            reloadData.model = value;
            

            this.reloadRefurbishProcess(reloadData);
        });

        $cpuSelect.on('change', (event) => {
            event.preventDefault();

            let value = $(event.currentTarget).val();

            reloadData.cpu = value;

            this.reloadRefurbishProcess(reloadData);
        });
        
        $gpuSelect.on('change', (event) => {
            event.preventDefault();

            let value = $(event.currentTarget).val();

            reloadData.gpu = value;

            this.reloadRefurbishProcess(reloadData);
        });

        $caseTypeSelect.on('change', (event) => {
            event.preventDefault();

            let value = $(event.currentTarget).val();

            reloadData.case_type = value;

            this.reloadRefurbishProcess(reloadData);
        });

        let that = this;
        $('.manual-percentage-wrapper').each(function () {
            const range = $(this).find("input.manual-battery-percentage");
            // const bubble = $(this).find(".bubble-value");

            $( range ).on( 'input', function( ) {
                $( this ).css( 'background', 'linear-gradient(90deg, #231d76 0%, #014958 ' + this.value + '%, rgb(233, 236, 239) ' + this.value + '%, rgb(233, 236, 239) 100%)' );
              } );

            //   background: linear-gradient(90deg, #231d76 0%, #014958 100%);

            // range.on('input', (event) => {
            //     that.setBubble(event.target, bubble);
            // });
            $( range ).css( 'background', 'linear-gradient(90deg, #231d76 0%, #014958 ' + range.value + '%, rgb(233, 236, 239) ' + range.value + '%, rgb(233, 236, 239) 100%)' );
            // that.setBubble(range, bubble);
        });


        let $copyCite = $('cite.copy');
        $copyCite.on('click', (event) => {
            event.preventDefault();

            let value = $(event.currentTarget).text();

            $(event.currentTarget).closest('.form-group').find('input[type="text"]').val(value);
            $(event.currentTarget).closest('.form-group').find('textarea').text(value);
        })

        let $diagnosticCodesList = $('.diagnostic-codes-list');
        let $diagnosticCodesListConfirm = $('.diagnostic-codes-list-confirm');
        let $diagnosticCodes = $('.diagnostic-codes');
        let $diagnosticCodeSearch = $('.diagnostic-codes-search');

        let $diagnosticCodesListOptions = $diagnosticCodesList.children('option');

        $diagnosticCodeSearch.on('keyup', (event) => {
            event.preventDefault();

            let value = $(event.currentTarget).val();
            let resultArray = [];

            if (value === '') {
                $diagnosticCodesListOptions.each((item, element) => {
                    resultArray.push(item, element);
                });
            } else {
                $diagnosticCodesListOptions.each((item, element) => {
                    let name = $(element).text();
                    if (name.includes(value)) {
                        resultArray.push(element);
                    }
                });
            }

            $diagnosticCodesList.html(resultArray);
        });

        if ($diagnosticCodesList.length > 0 && $diagnosticCodesListConfirm.length > 0) {

            let $addButton = $('.add-code');
            let $removeButton = $('.remove-code');

            $addButton.on('click', (event) => {
                event.preventDefault();
                event.stopPropagation();

                let selectedValues = $diagnosticCodesList.find('option:selected');

                if (selectedValues.length > 0) {
                    let diagnosticCodeValues = $diagnosticCodes.val();
                    if (diagnosticCodeValues.length === 0) {
                        diagnosticCodeValues = [];
                    } else {
                        diagnosticCodeValues = diagnosticCodeValues.split(',');
                    }

                    for (let i = 0; i < selectedValues.length; i++) {
                        let value = $(selectedValues[i]).val();

                        if ($.inArray(value, diagnosticCodeValues) === -1) {
                            let clone = $(selectedValues[i]).clone();
                            $diagnosticCodesListConfirm.append(clone);
                            diagnosticCodeValues.push(value);
                        }
                    }

                    $diagnosticCodes.val(diagnosticCodeValues);

                    if (diagnosticCodeValues.length > 1) {
                        $diagnosticCodes.val(diagnosticCodeValues.join(','));
                    }
                }
            });

            $removeButton.on('click', (event) => {
                event.preventDefault();

                let selectedValues = $diagnosticCodesListConfirm.find('option:selected');

                if (selectedValues.length > 0) {
                    let diagnosticCodeValues = $diagnosticCodes.val();
                    if (diagnosticCodeValues.length === 0) {
                        diagnosticCodeValues = [];
                    } else {
                        diagnosticCodeValues = diagnosticCodeValues.split(',');
                    }

                    $diagnosticCodes.val('');

                    let diagnosticCodeValuesArray = [];
                    let values = [];

                    for (let i = 0; i < selectedValues.length; i++) {
                        values.push($(selectedValues[i]).val());
                    }

                    for (let x = 0; x < diagnosticCodeValues.length; x++) {
                        if ($.inArray(diagnosticCodeValues[x], values) === -1) {
                            diagnosticCodeValuesArray.push(diagnosticCodeValues[x]);
                        }
                    }
                    console.log(diagnosticCodeValuesArray);

                    $diagnosticCodes.val(diagnosticCodeValuesArray);

                    if (diagnosticCodeValuesArray.length > 1) {
                        $diagnosticCodes.val(diagnosticCodeValuesArray.join(','));
                    }

                    $diagnosticCodesListConfirm.find('option:selected').remove();
                }
            });
        }
    }

    init() {
        this.handleEvent();
    }
}